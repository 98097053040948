<template>
  <page-content :show-search="true">
    <template #search>
      <table-search
        ref="search"
        :fields="search_fields"
        :default="search_default"
        @runSearch="runSearch"
        @changeField="searchChangeField"
      />
    </template>

    <template #default>
      <table-header
        ref="header"
        :title="title"
        :button-actions="header_actions"
        @runSearch="runSearch"
        search-tips="搜尋 型號標識 / 名稱 / 品牌 / 型號"
      />

      <table-content
        :per-page="page_condition.pageSize"
        :per-page-dropdown-enabled="true"
        @perPageChange="perPageChange"
        :columns="columns"
        :rows="rows"
        :total-rows="total_rows"
        :current-page="current_page"
        :action-options="table_actions"
        @runTableAction="runTableAction"
        @sortChange="sortChange"
        @pageChange="pageChange"
        :permission="view_permission"
      />
    </template>

  </page-content>
</template>

<script>
import common from '@/common'
import kioskUtils from '@/libs/kiosk-utils'
import TableContent from '@/components/TableContent'
import TableHeader from '@/components/TableHeader'
import TableSearch from '@/components/TableSearch'
import PageContent from '@/components/PageContent'

export default {
  components: {
    PageContent,
    TableSearch,
    TableHeader,
    TableContent,
  },
  name: 'kioskPeripheralModel',
  data() {
    return {
      title: common.getMenuName('kioskPeripheralModel'),
      // view_permission: common.checkPermission('Kiosk_NFC_List'),
      view_permission: true,
      rows: [],
      total_rows: 0,
      sort_condition: {},
      current_page: 1,
      page_condition: {
        pageIndex: 0,
        pageSize: common.getTablePerPage()
      },
      status_map: [],
      header_actions: [
        {
          text: 'common.add',
          variant: 'primary',
          fun: this.addData,
          // permission: 'Kiosk_Menu_Installer'
        },
      ],
      columns: [
        {
          label: 'common.row_index',
          field: 'row_index',
          sortable: false,
          width: '50px',
        },
        {
          label: '型號標識',
          field: 'bleName',
          width: '180px',
        },
        // {
        //   label: '連接方式',
        //   field: 'linkingType',
        //   width: '180px',
        // },
        {
          label: '類型',
          field: 'purposes',
          width: '130px',
          changeHTML: (raw, _row) => {
            return raw.map(val => `<span class="badge badge-secondary wh-font-family-monospace mr-05 font-weight-normal">${kioskUtils.purposeOpts.find(p => p.value === val)?.text ?? ''}</span>`)
              .join('')
          }
        },
        {
          label: '名稱',
          field: 'name',
          width: '220px',
        },
        {
          label: '品牌',
          field: 'brand',
          width: '150px',
        },
        {
          label: '型號',
          field: 'model',
          width: '150px',
        },
        {
          label: '型號備註',
          field: 'modelRemark',
          // width: '280px',
        },
        {
          label: 'common.action',
          field: 'action',
          width: '180px',
          sortable: false,
        },
      ],
      table_actions: [
        {
          text: 'common.edit',
          variant: 'primary',
          fun: 'editData',
          permission: ''
        },
        {
          text: 'common.delete',
          variant: 'danger',
          fun: 'deleteData',
          permission: ''
        }
      ],
      search_fields: [
        // {
        //   field: 'linkingType',
        //   label: '連接方式',
        //   type: 'select',
        //   options: common.getSelectOptions([
        //     { name: 'BLE' },
        //   ], 'name', 'name'),
        //   // show: common.isAdmin(),
        //   // clearable: false,
        // },
        {
          field: 'purpose',
          label: '類型',
          type: 'select',
          options: kioskUtils.purposeOpts,
          // show: common.isAdmin(),
          // clearable: false,
        },
      ],
      search_default: {
        // linkingType: 'BLE',
        purpose: null,
      },
      purposes: kioskUtils.purposeOpts,
    }
  },
  mounted() {
    if (!common.isAdmin()) {
      // common.getSearchFloorOptions('floorID', common.getRole().facilityID, this)
    } else {
      common.getSearchCompanyOptions('companyID', this)
      common.getSearchFacilityOptions('facilityID', common.getAdminCompanyId(), this)
      // common.getSearchFloorOptions('floorID', common.getAdminFacilityId(), this)
    }
    // common.getSearchRoomOptions('roomID', this.search_default.floorID, this)
    this.getList()
  },

  methods: {
    getList: async function () {
      if (!this.view_permission) return

      // const api = '/kiosk/WeHealth/GetKioskPeripheralModelList' // deprecated
      const api = '/kioskmanage/GetDeviceModels'
      const search = this.getSearchCondition()
      const url = common.getTableUrl(api, search, this.sort_condition, this.page_condition)
      const { data } = await common.apiGetData(url)

      // Assign value to current component
      this.total_rows = data.total
      this.rows = data.models
    },
    perPageChange(size) {
      this.page_condition.pageIndex = 0
      this.page_condition.pageSize = size
      common.setTablePerPage(size)
      this.getList()
    },
    addData: function () {
      this.$router.push({ name: 'kioskPeripheralModel_add' })
    },
    editData: function (data) {
      this.$router.push({
        name: 'kioskPeripheralModel_edit',
        params: { id: common.encrypt(data.bleName) }
      })
    },
    deleteData: function (data) {
      // common.showConfirm({
      //   title: this.$t('common.delete_tips') + data.modelTag,
      //   confirm_fun: async () => {
      //     await this.$requestWehealth({
      //       url: '/kiosk/WeHealth/DeleteKioskPeripheralModel',
      //       method: 'post',
      //       params: {
      //         modelTag: data.modelTag
      //       }
      //     })
      //     common.showToast({ title: this.$t('common.delete_success') })
      //     this.getList()
      //   }
      // })
    },

    runSearch: function () {
      this.current_page = 1
      this.page_condition.pageIndex = 0
      const vmOfSearch = this.$refs.search
      common.syncSearchFields({
        companyID: vmOfSearch.list.companyID,
        facilityID: vmOfSearch.list.facilityID,
        floorID: vmOfSearch.list.floorID,
        roomID: vmOfSearch.list.roomID,
      })
      this.getList()
    },
    async searchChangeField(field, value) {
      await common.searchChangeField({
        fieldName: field,
        newValue: value,
        currentVm: this,
        searchVm: this.$refs.search,
        isSyncGlobally: false
      })
    },

    runTableAction: function (action, data) {
      this[action](data)
    },
    sortChange: function (value) {
      this.sort_condition = value
      this.getList()
    },
    getSearchCondition: function () {
      this.$refs.search.buildSearchCondition()

      let where = {}
      where['searchContent'] = this.$refs.header.searchContent
      where = Object.assign(where, this.$refs.search.search_condition)

      return where
    },
    pageChange: function (value) {
      this.current_page = value
      this.page_condition.pageIndex = value - 1
      this.getList()
    },
    changeStatus: function (value) {
      for (const i in this.status_map) {
        if (this.status_map[i].value === value) {
          return this.status_map[i].text
        }
      }
    },
  }
}
</script>

<style lang="scss">

</style>
